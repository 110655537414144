import React, { useState } from "react";
import logo from "../Images/logo.png";
import img from "../Images/fav.png";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogOut } from "../reducers/commonReducer";

const SideMenu = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inactive, setInactive] = useState(true);
  const logOut = () => {
    dispatch(
      userLogOut({ "x-auth-token": localStorage.getItem("x-auth-token") })
    );
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div className="row print-hidden">
        <div className={`col ${inactive ? 'd-none' : ''}`}>
          <div className={`side-menu ${inactive ? " d-none" : ""}`}>
            <div className="top-section">
              <div className="logo">
                <img src={inactive ? img : logo} />
              </div>
              <ul className="nav fs-3 mt-1 flex-column">
                {localStorage.getItem("x-auth-token") &&
                  localStorage.getItem("userName") === "shyamg@4321" && (
                    <>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/reports">
                        {inactive ? (
                          <i className="bi bi-card-list"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-list me-2 ds"></i>
                                View Reports
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-wise-orders">
                        {inactive ? (
                          <i className="bi bi-cart-dash me-2 "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-cart-dash me-2 ds"></i>
                                Order's
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/local-sale">
                        {inactive ? (
                          <i className="bi bi-cart-dash me-2 "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-cart-dash me-2 ds"></i>
                                Local Sale
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-wise-delivered-orders">
                        {inactive ? (
                          <i className="bi bi-truck "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-truck me-2 ds"></i>
                                Delivered Orders
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/super-stockist">
                        {inactive ? (
                          <i className="bi bi-people-fill "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-people-fill me-2 ds"></i>
                                Super Stockist
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/asm-list">
                        {inactive ? (
                          <i className="bi bi-briefcase-fill "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-briefcase-fill me-2 ds"></i>
                                ASM
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-list">
                        {inactive ? (
                          <i className="bi bi-briefcase-fill "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-briefcase-fill me-2 ds"></i>
                                Sales Executive
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/created-distributor?type=Active">
                        {inactive ? (
                          <i className="bi bi-person-video2 "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-video2 me-2 ds"></i>
                                Active Distributor
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/inactive-distributor?type=Inactive">
                        {inactive ? (
                          <i className="bi bi-person-video2 "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-video2 me-2 ds"></i>
                                InActive Distributor
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/products">
                        {inactive ? (
                          <i className="bi bi-minecart"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-minecart me-2 ds"></i>
                                Products
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      {/* <NavLink
                       onClick={() => {
                  setInactive(true)
                }}
                   to="/categorys">
                        {inactive ? (
                          <i className="bi bi-minecart"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-minecart me-2 ds"></i>
                                Categories
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                       onClick={() => {
                  setInactive(true)
                }}
                   to="/groups">
                        {inactive ? (
                          <i className="bi bi-minecart"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-minecart me-2 ds"></i>
                                Groups
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink> */}
                    </>
                  )}
                {localStorage.getItem("x-auth-token") &&
                  (localStorage.getItem("userName") === "admin" ||
                    localStorage.getItem("userName") === "masteradmin" ||
                    localStorage.getItem("userName") === "Shyam" ||
                    localStorage.getItem("userName") === "shyamdevelopment") && (
                    <>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/reports">
                        <span className="border-bottom">
                          <li className="nav-item">
                            <span
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-card-list me-2 ds"></i>
                              View Reports
                              <hr />
                            </span>
                          </li>
                        </span>
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-wise-orders">
                        <span className="border-bottom">
                          <li className="nav-item">
                            <span
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-cart-dash me-2 ds"></i>
                              Order's
                              <hr />
                            </span>
                          </li>
                        </span>
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/local-sale">
                        {inactive ? (
                          <i className="bi bi-cart-dash me-2 "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-cart-dash me-2 ds"></i>
                                Local Sale
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-wise-delivered-orders">
                        {inactive ? (
                          <i className="bi bi-truck "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-truck me-2 ds"></i>
                                Delivered Orders
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/super-stockist">
                        {inactive ? (
                          <i className="bi bi-people-fill "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-people-fill me-2 ds"></i>
                                Super Stockist
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/asm-list">
                        {inactive ? (
                          <i className="bi bi-briefcase-fill "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-briefcase-fill me-2 ds"></i>
                                ASM
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-list">
                        {inactive ? (
                          <i className="bi bi-briefcase-fill "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-briefcase-fill me-2 ds"></i>
                                Sales Executive
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/contractors">
                        {inactive ? (
                          <i className="bi bi-person-lines-fill"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-lines-fill me-2 ds"></i>
                                Contractors
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/created-distributor?type=Active">
                        {inactive ? (
                          <i className="bi bi-person-video2 "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-video2 me-2 ds"></i>
                                Active Distributor
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/inactive-distributor?type=Inactive">
                        {inactive ? (
                          <i className="bi bi-person-video2 "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-video2 me-2 ds"></i>
                                InActive Distributor
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/distributor-list">
                        {inactive ? (
                          <i className="bi bi-universal-access-circle"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-universal-access-circle me-2 ds"></i>
                                Get Distributor
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/career-list">
                        {inactive ? (
                          <i className="bi bi-window-dock"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-window-dock me-2 ds"></i>
                                CareerList
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/complaints">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-bug ds"></i> Complaint's
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/feedback">
                        {inactive ? (
                          <i className="bi bi-card-checklist"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                FeedBack's
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/career-opening">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Career Opening
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/dilership-opening">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Dilership Opening
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/products">
                        {inactive ? (
                          <i className="bi bi-minecart"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-minecart me-2 ds"></i>
                                Products
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/categorys">
                        {inactive ? (
                          <i className="bi bi-minecart"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-minecart me-2 ds"></i>
                                Categories
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/groups">
                        {inactive ? (
                          <i className="bi bi-minecart"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-minecart me-2 ds"></i>
                                Groups
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/department">
                        {inactive ? (
                          <i className="bi bi-minecart"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-minecart me-2 ds"></i>
                                Department
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/state-wise-distributor-senior">
                        {inactive ? (
                          <i className="bi bi-person-lines-fill me-2 ds"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-lines-fill me-2 ds"></i>
                                State Senior Distributor
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <h4 className="text-light"><b>Website</b></h4>
                      <hr className="mt-0" />
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/web-contact-list">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Website Contact
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/client-review-list">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Client Review
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/industrial-visit">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Industrial Visit
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/newsletter-list">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Newsletter
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/banner">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Banners
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/blog">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Blogs
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/my-profile">
                        {inactive ? (
                          <i className="bi bi-person-square"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-square me-2 ds"></i>
                                My Profile
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                    </>
                  )}
                {localStorage.getItem("x-auth-token") &&
                  localStorage.getItem("userRole") === "14" && (
                    <>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/web-contact-list">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Website Contact
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/client-review-list">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Client Review
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/industrial-visit">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Industrial Visit
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/newsletter-list">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Newsletter
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/banner">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Banners
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/blog">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Blogs
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                    </>
                  )}

                {localStorage.getItem("x-auth-token") &&
                  localStorage.getItem("userRole") === "4" && (
                    <>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/career-list">
                        {inactive ? (
                          <i className="bi bi-window-dock"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-window-dock me-2"></i>
                                CareerList
                              </span>
                            </li>
                            <hr />
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/career-opening">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Career Opening
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/industrial-visit">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Industrial Visit
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                    </>
                  )}
                {localStorage.getItem("x-auth-token") &&
                  ((localStorage.getItem("userRole") === "15") || (localStorage.getItem("userRole") === "16")) && (
                    <>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/contractors">
                        {inactive ? (
                          <i className="bi bi-person-lines-fill"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-lines-fill me-2 ds"></i>
                                Contractors
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/my-profile">
                        {inactive ? (
                          <i className="bi bi-person-square ds"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-square ds"></i> My
                                Profile
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                    </>
                  )}
                {localStorage.getItem("x-auth-token") &&
                  localStorage.getItem("userRole") === "9" && (
                    <>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/dilership-opening">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Dilership Opening
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/distributor-list">
                        {inactive ? (
                          <i className="bi bi-bar-chart-line-fill"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-bar-chart-line-fill ds"></i>{" "}
                                Get Distributor
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/feedback">
                        {inactive ? (
                          <i className="bi bi-card-checklist"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                FeedBack's
                              </span>
                            </li>
                            <hr />
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/complaints">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-bug ds"></i> Complaint's
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/web-contact-list">
                        {inactive ? (
                          <i className="bi bi-bug"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-checklist ds"></i>{" "}
                                Website Contact
                              </span>
                              <hr />
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/my-profile">
                        {inactive ? (
                          <i className="bi bi-person-square ds"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-square ds"></i> My
                                Profile
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                    </>
                  )}
                {localStorage.getItem("x-auth-token") &&
                  localStorage.getItem("userRole") === "6" && (
                    <>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/dispatchby-wise-monthly-reporting">
                        {inactive ? (
                          <i className="bi bi-card-list"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-list me-2 ds"></i>
                                Dispatchby Monthly
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/dispatchby-wise-product-reporting">
                        {inactive ? (
                          <i className="bi bi-card-list"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-list me-2 ds"></i>
                                Dispatchby Productive wise Report
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/company-wise-orders">
                        {inactive ? (
                          <i className="bi bi-cart-dash me-2"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-cart-dash me-2 ds"></i>
                                Orders
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-wise-delivered-orders">
                        {inactive ? (
                          <i className="bi bi-truck"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-truck ds"></i> Delivered
                                Orders
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/super-stockists-wise-salesman">
                        {inactive ? (
                          <i className="bi bi-briefcase-fill"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-briefcase-fill me-2 ds"></i>
                                SalesMan
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/my-profile">
                        {inactive ? (
                          <i className="bi bi-person-square "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-square me-2 ds"></i>
                                My Profile
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                    </>
                  )}
                {localStorage.getItem("x-auth-token") &&
                  localStorage.getItem("userRole") === "7" && (
                    <>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/so-wise-monthly-reporting">
                        {inactive ? (
                          <i className="bi bi-card-list"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-list me-2 ds"></i>
                                Month wise Report
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/so-wise-product-reporting">
                        {inactive ? (
                          <i className="bi bi-card-list"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-list me-2 ds"></i>
                                Productive wise Report
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-wise-orders">
                        {inactive ? (
                          <i className="bi bi-cart-dash me-2"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-cart-dash me-2 ds"></i>
                                Orders
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-wise-delivered-orders">
                        {inactive ? (
                          <i className="bi bi-truck me-2"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-truck me-2 ds"></i>
                                Delivered Orders
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/distributor-salesman-wise">
                        {inactive ? (
                          <i className="bi bi-person-video2"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-video2 me-2 ds"></i>
                                Distributor's
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/my-profile">
                        {inactive ? (
                          <i className="bi bi-person-square"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-square ds"></i> My
                                Profile
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                    </>
                  )}
                {localStorage.getItem("x-auth-token") &&
                  localStorage.getItem("userRole") === "10" && (
                    <>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/asm-monthly-reporting">
                        {inactive ? (
                          <i className="bi bi-card-list"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-list me-2 ds"></i>
                                Month wise Report
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/asm-disrtibutor-product-reporting">
                        {inactive ? (
                          <i className="bi bi-card-list"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-card-list me-2 ds"></i>
                                Productive wise Report
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-wise-orders">
                        {inactive ? (
                          <i className="bi bi-cart-dash me-2"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-cart-dash me-2 ds"></i>
                                Orders
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-wise-delivered-orders">
                        {inactive ? (
                          <i className="bi bi-truck me-2"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-truck me-2 ds"></i>
                                Delivered Orders
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/salesman-list">
                        {inactive ? (
                          <i className="bi bi-briefcase-fill "></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-briefcase-fill me-2 ds"></i>
                                Sales Executive
                                <hr />
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          setInactive(true)
                        }}
                        to="/my-profile">
                        {inactive ? (
                          <i className="bi bi-person-square"></i>
                        ) : (
                          <span className="border-bottom">
                            <li className="nav-item">
                              <span
                                className="nav-link sideMenuLinks"
                                aria-current="page"
                                href="#"
                              >
                                <i className="bi bi-person-square ds"></i> My
                                Profile
                              </span>
                            </li>
                          </span>
                        )}
                      </NavLink>
                    </>
                  )}
                {(localStorage.getItem("x-auth-token") && localStorage.getItem("userRole") === "12") && (
                  <>
                    <NavLink
                      onClick={() => {
                        setInactive(true)
                      }}
                      to="/contractors">
                      {inactive ? (
                        <i className="bi bi-person-lines-fill"></i>
                      ) : (
                        <span className="border-bottom">
                          <li className="nav-item">
                            <span
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-person-lines-fill me-2 ds"></i>
                              Contractors
                              <hr />
                            </span>
                          </li>
                        </span>
                      )}
                    </NavLink>
                    <NavLink
                      onClick={() => {
                        setInactive(true)
                      }}
                      to="/my-profile">
                      {inactive ? (
                        <i className="bi bi-person-square ds"></i>
                      ) : (
                        <span className="border-bottom">
                          <li className="nav-item">
                            <span
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-person-square ds"></i> My
                              Profile
                            </span>
                          </li>
                        </span>
                      )}
                    </NavLink>
                  </>
                )}
                {(localStorage.getItem("x-auth-token") && localStorage.getItem("userRole") === "13") && (
                  <>
                    <NavLink
                      onClick={() => {
                        setInactive(true)
                      }}
                      to="/contractor">
                      {inactive ? (
                        <i className="bi bi-card-list"></i>
                      ) : (
                        <span className="border-bottom">
                          <li className="nav-item">
                            <span
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-card-list me-2 ds"></i>
                              Attendance
                              <hr />
                            </span>
                          </li>
                        </span>
                      )}
                    </NavLink>
                  </>
                )}
                {(localStorage.getItem("x-auth-token") && localStorage.getItem("userRole") === "17") && (
                  <>
                    <NavLink
                      onClick={() => {
                        setInactive(true)
                      }}
                      to="/distributor-list">
                      {inactive ? (
                        <i className="bi bi-card-list"></i>
                      ) : (
                        <span className="border-bottom">
                          <li className="nav-item">
                            <span
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-card-list me-2 ds"></i>
                              Get Distributor
                              <hr />
                            </span>
                          </li>
                        </span>
                      )}
                    </NavLink>
                  </>
                )}

                {(localStorage.getItem("x-auth-token") && localStorage.getItem("userRole") === "18") && (
                  <>
                    <NavLink
                      onClick={() => {
                        setInactive(true)
                      }}
                      to="/reports">
                      {inactive ? (
                        <i className="bi bi-card-list"></i>
                      ) : (
                        <span className="border-bottom">
                          <li className="nav-item">
                            <span
                              className="nav-link sideMenuLinks"
                              aria-current="page"
                              href="#"
                            >
                              <i className="bi bi-card-list me-2 ds"></i>
                              View Reports
                              <hr />
                            </span>
                          </li>
                        </span>
                      )}
                    </NavLink>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="col">
          <div className={`${inactive ? "content-active" : "content open-menu-tab"}`}>
            <div className="d-flex justify-content-between  bg-side border-dark border-bottom">
              <div className="p-2">
                <span onClick={() => {
                  setInactive(!inactive)
                }}>
                  <p className="mb-0">{inactive ? <i className="listmenubtn text-white bi bi-list"></i> : <i className="crossbtn bi bi-x-lg text-white"></i>}</p>
                </span>
              </div>
              <div className="p-2">
                <button className="btn">
                  <Link to="/my-profile">
                    <span className="nav-link">
                      <i className="bi bi-person-fill"></i>
                    </span>
                  </Link>
                </button>
                <button onClick={logOut} className="btn btn-outline-primary">
                  <span
                    className="nav-link"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Log Out
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
